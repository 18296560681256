#login{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

#login .box{
    /* width: 350px; */
    position: relative;
    background: rgb(230,230,230);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
}